<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Email templates</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Email templates
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
<!--            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">-->
<!--              <div class="row">-->
<!--                <v-col cols="12" sm="6" md="4">-->
<!--                  <v-select v-model="search.roles" :items="roles"-->
<!--                            label="Recipient"-->
<!--                            item-text="text" clearable-->
<!--                            item-value="value"-->
<!--                            outlined  v-on:keyup.enter="getAllRoles"-->
<!--                            dense-->
<!--                  ></v-select>-->
<!--                </v-col>-->
<!--                <v-col cols="12" sm="6" md="2">-->
<!--                  <v-btn-->
<!--                      @click="getAllRoles"-->
<!--                      class="btn btn-block btn-primary"-->
<!--                      style="color: #fff;"-->
<!--                  >-->
<!--                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;-->
<!--                    Search-->
<!--                  </v-btn>-->
<!--                </v-col>-->
<!--              </div>-->
<!--            </div>-->
            <div class="table-responsive" style="table-layout: fixed">
              <table class="table">
                <thead>
                <th>&nbsp;Recipient</th>
                <th>Options</th>

                </thead>
                <tbody>
                <tr v-for="(role, index) of filter_roles" :key="index">
                  <td>
                    <a @click="openEmailTemplateList(role.value)">{{ role.text=='Accountholders' ? 'Enroller' : role.text }}
                      <!--<i class="fas fa-circle dot-active"> </i>-->
                    </a>
                  </td>
                  <td>
                   <a href="#" @click="openEmailTemplateList(role.value)">View email template</a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import EmailTemplateService from "@/services/email-template/EmailTemplateService";

const emailTemplate = new EmailTemplateService();

export default {
  name: "email-templates",
  components: {},
  data() {
    return {
      email_templates: [],

      page: null,
      total: null,
      perPage: null,
      search: {
        active: "active",
        roles:null,
      },
      roles: [
        {'text': 'Enroller', 'value': 'account_holder'},
        {'text': 'General', 'value': 'general'},
        {'text': 'Admin', 'value': 'admin'},
        {'text': 'Candidate', 'value': 'candidate'},
        {'text': 'Examiner', 'value': 'examiner'},
        {'text': 'Supervisor', 'value': 'supervisor'},
        {'text': 'Online Marker', 'value': 'online_marker'},
        {'text': 'School Account', 'value': 'school_account'},
        {'text': 'Venue', 'value': 'venue'},
      ],
      filter_roles:[
        {'text': 'Accountholders', 'value': 'account_holder'},
        {'text': 'General', 'value': 'general'},
        {'text': 'Admin', 'value': 'admin'},
        {'text': 'Candidate', 'value': 'candidate'},
        {'text': 'Examiner', 'value': 'examiner'},
        {'text': 'Supervisor', 'value': 'supervisor'},
        {'text': 'Online Marker', 'value': 'online_marker'},
        {'text': 'School Account', 'value': 'school_account'},
        {'text': 'Venue', 'value': 'venue'},
      ],
    };
  },
  mounted() {
   this.getEmailTemplateRoles();

  },
  methods: {
    getAllRoles(){
      this.filter_roles=this.roles;
      if(this.search.roles){
        this.filter_roles = this.filter_roles.filter(role => this.search.roles==role.value);
      }
    },
    getEmailTemplateRoles() {
      emailTemplate
          .emailTemplateRoles(this.search)
          .then(response => {
            this.roles = response.data;
          });
    },
    __edit(id) {
      this.$router.push({
        name: "email-template-update",
        params: {id: id}
      });
    },
    searchEmailTemplate() {
      this.getEmailTemplateRoles();
    },
    openEmailTemplateList(role) {
      this.$router.push({name: 'email-template', params: {role: role}})
    },
    deleteEducation(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item permanently?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            educationService
                .delete(id)
                .then(response => {
                  this.$snotify.success("Deleted successfully");

                  this.getEducation();
                })
                .catch(error => {
                });
          }
        }
      });
    }
  }
};
</script>
